import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import CustomInput from "components/CustomInput";
import CustomSelect from "components/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFamily } from "state";
import Navbar from "scenes/navbar";
import Error from "components/Error";

const creationSchema = yup.object().shape({
  familyName: yup.string().min(3, "Too short").required("Required"),
  privacy: yup
    .string()
    .oneOf(["public", "private"], "Invalid Input")
    .required("Required"),
});

const AddInformations = ({ status, setStatus }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");

  const onSubmit = async (values) => {
    try {
      const res = await fetch("http://192.168.178.36:5511/createFamily", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify([token, values["privacy"], values["familyName"]]),
      });
      setStatus();
      dispatch(setCurrentFamily(await res.json()["NewFamilyId"]));
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
      >
        <div style={{ padding: "25px", color: "#282d2e", fontSize: "33px" }}>
          Create a new family!
        </div>
        <Formik
          initialValues={{ familyName: "", privacy: "" }}
          validationSchema={creationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form>
              <div sx={{ paddingBottom: "10px" }}>
                <CustomInput
                  label="Family Name"
                  name="familyName"
                  type="text"
                  placeholder="Enter Family Name"
                />
              </div>
              <div paddingBottom="10px">
                <CustomSelect label="Privacy" name="privacy" placeholder="">
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </CustomSelect>
              </div>
              {/* <Grid item>
              <div {...getRootProps()}>
                <input {...getInputProps()} name="files" />
                <Box sx={{ border: 1, borderColor: "red", p: "1rem 2rem" }}>
                  <p>Upload yout family's photos here</p>
                  
                  {files.map((fileWrapper, index) => (
                    <SingleFileUploadWithProgress
                      onUpload={onUpload}
                      onDelete={onDelete}
                      key={index}
                      file={fileWrapper.file}
                    />
                  ))}
                </Box>
              </div>
            </Grid> */}

              {props.errors.name && (
                <div id="feedback" style={{ color: "red" }}>
                  {props.errors.name}
                </div>
              )}
              <button
                type="submit"
                style={{
                  height: "35px",
                  width: "100px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddInformations;
