import React, { useEffect, useState } from "react";
import UserImage from "./blank-profile-picture.webp";
import "./index.css";
import CommentForm from "components/CommentForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Comment = ({
  comment,
  replies,
  currentUserId,
  deleteComment,
  activeComment,
  addComment,
  setActiveComment,
  parentId = null,
  updateComment,
}) => {
  const token = useSelector((state) => state.token);
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment["created_at"]) > fiveMinutes;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment["user_id"] && !timePassed;
  const canDelete = currentUserId === comment["user_id"] && !timePassed;
  const createdAt = new Date(comment["created_at"]).toLocaleDateString();
  const isReplying =
    activeComment &&
    activeComment["type"] === "replying" &&
    activeComment["Id"] === comment["comment_id"];
  const isEditing =
    activeComment &&
    activeComment["type"] === "editing" &&
    activeComment["Id"] === comment["comment_id"];
  const replyId = parentId ? parentId : comment["comment_id"];
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${comment["user_id"]}/profile_picture?is_thumbnail`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setUserProfilePicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="comment">
      <div
        className="comment-image-container"
        onClick={() => navigate(`/userPage/${comment["user_id"]}`)}
      >
        <img src={userProfilePicture || UserImage} className="user-image" />
      </div>
      <div className="comment-right-part">
        <div className="comment-content">
          <div
            className="comment-author"
            onClick={() => navigate(`/userPage/${comment["user_id"]}`)}
          >
            {comment["username"]}
          </div>
          <div>{createdAt}</div>
        </div>
        {!isEditing && <div className="comment-text">{comment["body"]}</div>}
        {isEditing && (
          <CommentForm
            submitLabel="Update"
            hasCancelButton={true}
            initalText={comment["body"]}
            handleSubmit={(text) => updateComment(text, comment["comment_id"])}
            handleCancel={() => setActiveComment(null)}
          />
        )}
        <div className="comment-actions">
          {canReply && (
            <div
              className="comment-action"
              onClick={() => {
                setActiveComment({
                  Id: comment["comment_id"],
                  type: "replying",
                });
              }}
            >
              Reply
            </div>
          )}
          {canEdit && (
            <div
              className="comment-action"
              onClick={() =>
                setActiveComment({ Id: comment["comment_id"], type: "editing" })
              }
            >
              Edit
            </div>
          )}
          {canDelete && (
            <div
              className="comment-action"
              onClick={() => deleteComment(comment["comment_id"])}
            >
              Delete
            </div>
          )}
        </div>
        {isReplying && (
          <CommentForm
            submitLabel="Reply"
            handleSubmit={(text) => addComment(text, replyId)}
            hasCancelButton={true}
            handleCancel={() => setActiveComment(null)}
          />
        )}
        {replies.length > 0 && (
          <div className="replies">
            {replies.map((reply) => {
              return (
                <Comment
                  comment={reply}
                  replies={[]}
                  key={reply["comment_id"]}
                  currentUserId={currentUserId}
                  activeComment={activeComment}
                  setActiveComment={setActiveComment}
                  deleteComment={deleteComment}
                  addComment={addComment}
                  updateComment={updateComment}
                  parentId={comment["comment_id"]}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
