import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import "./index.css";
import { useSelector } from "react-redux";
import Error from "components/Error";
import ChatMessage from "components/chatMessage";
import NoResults from "components/NoResults";

const Chat = () => {
  const test = 10;
  const FETCHED_AT_TIME = 15;
  const params = useParams();
  const receiver = params.receiver;
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const username = useSelector((state) => state.username);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [noMessages, setNoMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [receiverUsername, setReceiverUsername] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageNumber((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMessages]
  );

  const sendMessage = async (e) => {
    try {
      setMessage("");
      e.preventDefault();
      if (!message) {
        return "Can't send empty message";
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/message`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ receiver, content: message }),
        }
      );
      setMessages((curr) => [
        {
          created_at: new Date(),
          message: message,
          sender: user,
          receiver: receiver,
        },
        ...curr,
      ]);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (pageNumber === 0) {
      return;
    }
    fetchData();
  }, [pageNumber]);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/messages/${receiver}?page_number=${pageNumber}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setNoMessages(true);
        return;
      } else {
        setNoMessages(false);
      }
      const messages = (await res.json())["messages"];
      setMessages((curr) => [...curr, ...messages]);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchUsername = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${receiver}/username`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setReceiverUsername((await res.json())["username"]);
  };

  useEffect(() => {
    if (receiver == user) {
      navigate("/profilePage");
    }
    fetchUsername();
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div className="padding-top">
        <div className="message-page-container">
          <div className="messages-container">
            {noMessages && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoResults
                  primaryText="No messages"
                  secondaryText="Start your conversation"
                />
              </div>
            )}
            {messages?.map((mess, index) => {
              if (
                pageNumber * FETCHED_AT_TIME + FETCHED_AT_TIME ===
                index + 1
              ) {
                return (
                  <div ref={lastBookElementRef}>
                    <ChatMessage
                      text={mess["message"]}
                      author={mess["sender"]}
                      isUserSender={mess["sender"] === user}
                      date={mess["created_at"]}
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <ChatMessage
                      text={mess["message"]}
                      author={mess["sender"]}
                      isUserSender={mess["sender"] === user}
                      date={mess["created_at"]}
                      sender_username={
                        mess["sender"] === user ? username : receiverUsername
                      }
                    />
                  </div>
                );
              }
            })}
          </div>
          <form onSubmit={sendMessage}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Chat;
