import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-svgrepo-com.svg";
import { useSelector } from "react-redux";

const Field = ({
  name,
  endpoint,
  valueState,
  type,
  person_id,
  inputType = "text",
}) => {
  const token = useSelector((state) => state.token);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(valueState);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const submit = async () => {
    let res;
    if (type === "user") {
      res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${endpoint}/${value}`,
        {
          method: "PATCH",
          headers: {
            "Allow-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (type === "person") {
      res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${person_id}/${endpoint}/${value}`,
        {
          method: "PATCH",
          headers: {
            "Allow-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    if (res?.status === 200) {
      setEdit(false);
    }
  };

  useEffect(() => {
    setValue(valueState);
  }, [valueState]);

  return (
    <tr>
      <td>{name}: </td>
      {!edit ? (
        <>
          <td>{value || "No informations"}</td>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEdit(true);
            }}
          >
            <EditIcon />
          </td>
        </>
      ) : (
        <td>
          <input
            type={inputType}
            value={value}
            onChange={(e) => handleChange(e)}
          />
          <button
            onClick={() => {
              submit();
            }}
          >
            Save
          </button>
          <button
            onClick={() => {
              setValue(valueState);
              setEdit(false);
            }}
          >
            Cancel
          </button>
        </td>
      )}
    </tr>
  );
};

export default Field;
