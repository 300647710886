import Field from "components/field/Field";
import React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-svgrepo-com.svg";

const General = ({ accountInfo }) => {
  return (
    <table
      style={{
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        borderSpacing: "16px",
        fontSize: "20px",
      }}
    >
      <Field
        name={"Username"}
        endpoint="username"
        valueState={accountInfo["username"]}
        type="user"
      />
      <Field
        name={"First Name"}
        endpoint="first_name"
        valueState={accountInfo["first_name"]}
        type="user"
      />
      <Field
        name={"Last Name"}
        endpoint="last_name"
        valueState={accountInfo["last_name"]}
        type="user"
      />
      <Field
        name={"Email"}
        endpoint="email"
        valueState={accountInfo["email"]}
        type="user"
      />
      <Field
        name={"Phone Number"}
        endpoint="phone_number"
        valueState={accountInfo["phone_number"]}
        type="user"
      />
    </table>
  );
};

export default General;
