import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "scenes/navbar";

const ChooseSubscription = () => {
  const [chosenPlan, setChosenPlan] = useState("premium");
  const navigate = useNavigate();

  const choosePlan = () => {
    navigate(`/payment/${chosenPlan}`);
  };

  return (
    <div>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto auto",
            width: "80%",
            height: "600px",
          }}
        >
          <div></div>
          <div>Standard</div>
          <div>Medium</div>
          <div>Premium</div>
          <div>Storage Size</div>
          <div>5GB</div>
          <div>15GB</div>
          <div>50GB</div>
          <div>Photo Search by Title and Description</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Photo Search by Location</div>
          <div>No</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Search Faces in Photos</div>
          <div>No</div>
          <div>No</div>
          <div>Yes</div>
          <div>Search Our Archival Photos</div>
          <div>No</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Connect with other people</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Yes</div>
          <div>Price</div>
          <div>3€ / Month (for a year)</div>
          <div>5€ / Month (for a year)</div>
          <div>7€ / Month (for a year)</div>
          <div>Choose Your Plan</div>

          <div>
            <input
              type="radio"
              id="standard"
              name="plan"
              onChange={(e) => setChosenPlan("standard")}
            ></input>
          </div>
          <div>
            <input
              type="radio"
              id="medium"
              name="plan"
              onChange={(e) => setChosenPlan("medium")}
            ></input>
          </div>
          <div>
            <input
              type="radio"
              id="premium"
              name="plan"
              defaultChecked
              onChange={(e) => setChosenPlan("premium")}
            ></input>
          </div>
        </div>
        <div>
          <button onClick={() => choosePlan()}>Next Step</button>
        </div>
      </div>
    </div>
  );
};

export default ChooseSubscription;
