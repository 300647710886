import React, { useState } from "react";
import Navbar from "scenes/navbar";
import ProfilePicture from "./blank-profile-picture.webp";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Error from "components/Error";
import "./index.css";
import AddSinglePicture from "components/addSinglePicture";
import MediumImage from "components/mediumImage";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-svgrepo-com.svg";

const ProfilePage = () => {
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [profileData, setProfileData] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [pictureUpload, setPictureUpload] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");

  const fetchProfilePicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${user_id}/profile_picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setProfilePicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProfileData([]);
        const res = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/profile`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const temp = await res.json();
        setProfileData(temp);
        if (temp["picture_path"]) {
          fetchProfilePicture();
        }
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      {pictureUpload && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "120",
          }}
        >
          <AddSinglePicture type="profile" setIsOpen={setPictureUpload} />
        </div>
      )}
      <div className="profile-page-container">
        <div
          style={{
            width: "350px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div onClick={() => setPictureUpload(true)}>
            <MediumImage url={profilePicture} />
          </div>
          <p style={{ fontSize: "25px", marginTop: "14px" }}>
            <span>{profileData?.["first_name"]} </span>
            <span>{profileData?.["last_name"]}</span>
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "350px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "28px" }}>General Informations</p>
              <SettingsIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/settings`);
                }}
              />
            </div>
            <table>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>Name:</td>
                <td>{profileData?.["username"]}</td>
              </tr>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>Email:</td>
                <td>{profileData?.["email"]}</td>
              </tr>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>Joined:</td>
                <td>
                  {new Date(profileData?.["created_at"]).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>UserId :</td>
                <td>{profileData?.["user_id"]}</td>
              </tr>
            </table>
            <p style={{ fontSize: "28px" }}>About Me</p>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "14px",
              justifyContent: "space-between",
            }}
          >
            <a
              href="/friends"
              style={{
                textDecoration: "inherit",
                color: "inherit",
                cursor: "auto",
              }}
            >
              <div style={{ cursor: "pointer" }}>
                <p style={{ fontSize: "26px" }}>
                  Friends{" "}
                  <span style={{ color: "lightgray" }}>
                    ({profileData?.["friend_count"] || 0})
                  </span>
                </p>
              </div>
            </a>
            <a
              href={`/photoGallery`}
              style={{
                textDecoration: "inherit",
                color: "inherit",
                cursor: "auto",
              }}
            >
              <div style={{ cursor: "pointer" }}>
                <p style={{ fontSize: "26px" }}>
                  Photos{" "}
                  <span style={{ color: "lightgray" }}>
                    ({profileData?.["photo_count"] || 0})
                  </span>
                </p>
              </div>
            </a>
            <a
              href={`/peoplePage`}
              style={{
                textDecoration: "inherit",
                color: "inherit",
                cursor: "auto",
              }}
            >
              <div style={{ cursor: "pointer" }}>
                <p style={{ fontSize: "26px" }}>
                  People{" "}
                  <span style={{ color: "lightgray" }}>
                    ({profileData?.["people_count"] || 0})
                  </span>
                </p>
              </div>
            </a>
            <div></div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default ProfilePage;
