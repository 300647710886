import React from "react";
import styles from "./ChatMessage.module.css";
import BlankProfilePicture from "./blank-profile-picture.webp";

const ChatMessage = ({
  text,
  date,
  author,
  profilePicture,
  isUserSender,
  sender_username,
}) => {
  console.log(isUserSender);
  return (
    <div
      className={
        isUserSender
          ? styles["message-wrapper-container-sent-message"]
          : styles["message-wrapper-container-received-message"]
      }
    >
      <div
        className={
          isUserSender
            ? styles["profile-picture-container-sent"]
            : styles["profile-picture-container-received"]
        }
      >
        <img
          src={profilePicture || BlankProfilePicture}
          alt="Profile picture"
        />
      </div>
      <div className={styles["author-date-text-container"]}>
        <p className={styles["sender-username"]}>{sender_username}</p>
        <div className={styles["message-container"]}>
          <p
            className={
              isUserSender
                ? `${styles["main-message-sent"]} ${styles["main-message"]}`
                : `${styles["main-message-received"]} ${styles["main-message"]}`
            }
          >
            {text}
          </p>
          <div
            className={
              isUserSender
                ? `${styles["triangle-sent"]} ${styles["triangle"]}`
                : `${styles["triangle-received"]} ${styles["triangle"]}`
            }
          ></div>
        </div>
        <p className={styles["message-date"]}>
          {new Date(date).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
