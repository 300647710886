import React, { useState } from "react";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  initalText = "",
  handleCancel,
}) => {
  const [text, setText] = useState(initalText);
  const isTexteareaDisabled = text.length === 0;
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(text);
    setText("");
  };
  return (
    <form action={onSubmit}>
      <textarea
        className="comment-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button
        className="comment-form-button"
        disabled={isTexteareaDisabled}
        onClick={(e) => onSubmit(e)}
      >
        {submitLabel}
      </button>
      {hasCancelButton && (
        <button
          type="button"
          className="comment-form-button comment-form-cancel-button"
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
      )}
    </form>
  );
};

export default CommentForm;
