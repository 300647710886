import axios from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./AddSinglePicture.module.css";

const AddSinglePicture = ({
  type,
  status,
  setStatus,
  personId,
  setIsOpen,
  albumId,
}) => {
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({ file, errors: [] }));
    setFiles(mappedAcc);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  const formSubmit = async () => {
    try {
      if (type === "profile") {
        files.map(async (fileWrapper) => {
          const data = new FormData();
          data.append("file", fileWrapper.file);
          await axios
            .post(
              `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${user_id}/profile_picture`,
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  cors: true,
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .catch((e) => {
              console.log(e.response);
            });
        });
      } else if (type === "person") {
        files.map(async (fileWrapper) => {
          const data = new FormData();
          data.append("file", fileWrapper.file);
          data.append("name", fileWrapper.file.name);
          await axios
            .post(
              `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${personId}/picture`,
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  cors: true,
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .catch((e) => {
              console.log(e.response);
            });
        });
      } else if (type === "albumThumbnail") {
        files.map(async (fileWrapper) => {
          const data = new FormData();
          data.append("file", fileWrapper.file);
          await axios
            .post(
              `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}/thumbnail`,
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  cors: true,
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .catch((e) => {
              console.log(e.response);
            });
        });
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  return (
    <div
      style={{
        height: "90vh",
        width: "90vw",
        position: "absolute",
        top: "10vh",
        left: "10vw",
        zIndex: "130",
        overflow: "auto",
      }}
    >
      <Formik initialValues={{}} onSubmit={formSubmit}>
        {({ values, errors }) => (
          <Form>
            {files.length ? (
              <>
                <div
                  {...getRootProps()}
                  style={{
                    display: "flex",
                    width: "1200px",
                    height: "800px",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto auto",
                      width: "100%",
                      height: "90%",
                      margin: "40px",
                      backgroundColor: "#f5eded",
                      padding: "30px",
                    }}
                  >
                    <input {...getInputProps()} />
                    {files.map((fileWrapper, index) => {
                      return (
                        <div
                          style={{
                            height: "60px",
                            width: "160px",
                            backgroundColor: "#a9d8b8",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {fileWrapper["file"]["path"]}
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                              position: "absolute",
                              right: "0",
                              top: "0",
                              fontSize: "15px",
                              backgroundColor: "red",
                              height: "15px",
                              width: "15px",
                            }}
                            onClick={() => files.splice(index, 1)}
                          >
                            X
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    width: "1200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "30px",
                  }}
                >
                  <button
                    style={{
                      width: "150px",
                      height: "80px",
                      backgroundColor: "red",
                    }}
                    onClick={() => {
                      console.log("close");
                      setIsOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    style={{
                      height: "40px",
                      width: "150px",
                      backgroundColor: "orange",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                  {status && (
                    <button
                      style={{
                        height: "40px",
                        width: "150px",
                        backgroundColor: "orange",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Skip for now
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  {...getRootProps()}
                  style={{
                    display: "flex",
                    width: "1200px",
                    height: "800px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "90%",
                      margin: "40px",
                      backgroundColor: "#f5eded",
                    }}
                  >
                    <input {...getInputProps()} />
                    <p style={{ fontSize: "20px", color: "#282d2e" }}>
                      Choose an image
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "1200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "30px",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      width: "150px",
                      height: "80px",
                      backgroundColor: "red",
                    }}
                    onClick={() =>
                      setIsOpen((curr) => {
                        return !curr;
                      })
                    }
                  >
                    Close
                  </button>
                  <button
                    style={{
                      height: "40px",
                      width: "150px",
                      backgroundColor: "orange",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                  {status && (
                    <button
                      style={{
                        height: "40px",
                        width: "150px",
                        backgroundColor: "orange",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Skip for now
                    </button>
                  )}
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSinglePicture;
