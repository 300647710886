import React from "react";

const NoResults = ({
  primaryText = "No Results",
  secondaryText = "Change your search parameters and try again",
  redirectToForum = false,
  url = null,
}) => {
  return (
    <div
      style={{
        height: "150px",
        width: "500px",
        display: "flex",
        flexDirection: "column",
        border: "4px solid #f79a60",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
      }}
    >
      <h2>{primaryText}</h2>
      <h3>
        {secondaryText}
        {url && <a href={url}>here</a>}
      </h3>
      {redirectToForum && (
        <p>
          If you want to find out more about our search engines go{" "}
          <a href="/">here</a>
        </p>
      )}
    </div>
  );
};

export default NoResults;
