import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "./blank-profile-picture.webp";
import OptionsDots from "../../assets/icons/dots-3-vertical-svgrepo-com.svg";
import { useSelector } from "react-redux";
import ConfirmDelete from "components/ConfirmDelete";
import styles from "./PersonItem.module.css";

const PersonItem = ({ person, searched, index }) => {
  const navigate = useNavigate();
  const [personPicture, setPersonPicture] = useState("");
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");
  const [optionsDropdown, setOptionsDropdown] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const fetchPersonPicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${person["person_id"]}/picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setPersonPicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const deletePerson = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${person["person_id"]}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDeleteConfirmation(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (person["picture_path"]) {
      fetchPersonPicture();
    }
  }, []);

  return (
    <>
      {deleteConfirmation && (
        <ConfirmDelete
          functionAfterConfirm={deletePerson}
          setIsOpen={setDeleteConfirmation}
        />
      )}
      <div
        className={styles["person-container"]}
        onClick={() => navigate(`/personPage/${person["person_id"]}`)}
      >
        <div>
          <img
            src={personPicture || ProfilePicture}
            className={styles["person-image"]}
          />
        </div>
        <div className={styles["person-name"]}>
          {person["first_name"] ? (
            <span>{person["first_name"]}</span>
          ) : (
            <span className={styles["unknown-name"]}>Unknown</span>
          )}
          {person["last_name"] ? (
            <span>{person["last_name"]}</span>
          ) : (
            <span className={styles["unknown-name"]}>Unknown</span>
          )}
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOptionsDropdown((curr) => !curr);
            }}
            style={{ all: "unset" }}
          >
            <img src={OptionsDots} className={styles["dropdown-dots"]} />
          </button>
          {optionsDropdown && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "#f9f9f9",
                width: "100px",
                display: "flex",
                flexDirection: "column",
                zIndex: "2",
              }}
            >
              <button
                style={{ width: "100%", height: "35px", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/personPage/${person["person_id"]}`);
                }}
              >
                View profile
              </button>
              {!searched && (
                <button
                  style={{ width: "100%", height: "35px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonItem;
