import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import Error from "components/Error";
import styles from "./LoginForm.module.css";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (email, password) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/login`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const returnedData = await res.json();

      if (returnedData["token"]) {
        dispatch(
          setLogin({
            user: returnedData["user_id"],
            token: returnedData["token"],
            username: returnedData["username"],
          })
        );
        navigate("/home");
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const handleFormSubmit = async () => {
    await login(email, password);
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}
    >
      {error && <Error message={error} closingFunction={setError} />}
      <div className={styles["login-form-container"]}>
        <input
          className={styles["inputs"]}
          placeholder="E-mail"
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={styles["inputs"]}
          placeholder="Password"
          type="text"
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className={styles["submit-button"]}
          type="button"
          onClick={() => handleFormSubmit()}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
