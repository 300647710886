import React from "react";
import { useNavigate } from "react-router-dom";

const FoundImage = ({ photo, type }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        cursor: "pointer",
        border: "1px solid #dae2f0",
        padding: "20px",
        borderBottom: "none",
      }}
      onClick={() => {
        console.log(photo);
        type === "geo"
          ? navigate(`/photoPage/${photo["informations"]["aws_filename"]}`)
          : navigate(
              `/photoPage/${photo["informations"]["aws_filename"]}/?faceId=${photo["informations"]["face_id"]}&Box0=${photo["informations"]["position_on_image"][0]}&Box1=${photo["informations"]["position_on_image"][1]}&Box2=${photo["informations"]["position_on_image"][2]}&Box3=${photo["informations"]["position_on_image"][3]}`
            );
      }}
    >
      <div
        style={{ width: "400px", display: "flex", justifyContent: "center" }}
      >
        <img src={photo?.["Url"]} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div style={{ fontSize: "23px" }}>
          {photo["informations"]?.["photo_name"] || "No name yet"}
        </div>
        <div style={{ fontSize: "20px" }}>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "140px" }}>Description: </td>
                <td style={{ minWidth: "140px" }}>
                  {photo["informations"]?.["description"]}
                </td>
              </tr>
              <tr>
                <td style={{ width: "140px" }}>Distance: </td>
                <td style={{ minWidth: "140px" }}>
                  {type === "geo"
                    ? photo["informations"]["geo_distance"].toFixed(3)
                    : photo["informations"]?.["distance"].toFixed(3)}
                </td>
                <td style={{ width: "140px" }}>Owner: </td>
                <td style={{ minWidth: "140px" }}>
                  {photo["informations"]?.["username"]}
                </td>
              </tr>
              <tr>
                <td style={{ width: "140px" }}>When Taken: </td>
                <td style={{ minWidth: "140px" }}>
                  {photo["informations"]?.["when_taken"]}
                </td>
                <td style={{ width: "140px" }}>Photo Location: </td>
                <td style={{ minWidth: "140px" }}>
                  {photo["informations"]?.["photo_location"]}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FoundImage;
