import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-svgrepo-com.svg";
import { useSelector } from "react-redux";

const Security = ({ formInfo, setFormInfo }) => {
  const token = useSelector((state) => state.token);
  const [editPassword, setEditPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      setFormInfo("Passwords do not match");
      return;
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/password`,
      {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      }
    );
    if (res.status === 200) {
      setFormInfo("Password changed successfully");
    } else {
      setFormInfo("Password change failed");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <table
        style={{
          padding: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          borderSpacing: "16px",
          fontSize: "20px",
        }}
      >
        <tr>
          <td>Password: </td>
          <td>********</td>
          <td onClick={() => setEditPassword(true)}>
            <EditIcon />
          </td>
          {editPassword && (
            <>
              <td style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="oldPassword">Current Password</label>
                <input
                  id="oldPassword"
                  type="password"
                  value={oldPassword}
                  placeholder="Current Password"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <label htmlFor="newPassword">New Password</label>
                <input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </td>
              <td style={{ color: "red" }}>{formInfo}</td>
              <td>
                <button onClick={() => changePassword()}>Submit</button>
              </td>
              <td>
                <button onClick={() => setEditPassword(false)}>Cancel</button>
              </td>
            </>
          )}
        </tr>
      </table>
    </div>
  );
};

export default Security;
