import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import { useNavigate } from "react-router-dom";
import Error from "components/Error";
import GalleryItem from "components/galleryItem";
import styles from "./SavedPhotos.module.css";
import CurrentPageInfo from "components/CurrentPageInfo";
import NoResults from "components/NoResults";

const SavedPhotos = () => {
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [savedFilenames, setSavedFilenames] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/saved`,
          {
            methods: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status !== 200) {
          return;
        }
        const photos = (await res.json())["data"];
        setSavedFilenames(photos);
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div className="page-container">
        <CurrentPageInfo
          pageName="Saved Photos"
          error={error}
          setError={setError}
        />
        <div className={styles["photos-container"]}>
          {savedFilenames.length > 0 ? (
            savedFilenames.map((filename, index) => {
              return (
                <div
                  className={styles["saved-photos-container"]}
                  onClick={() => navigate(`/photoPage/${filename}`)}
                >
                  <GalleryItem imageFilename={filename} />
                </div>
              );
            })
          ) : (
            <div className={styles["no-photos-container"]}>
              <NoResults
                primaryText="You didn't save any photos yet..."
                secondaryText="You can save photos by clicking a button under an image"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedPhotos;
