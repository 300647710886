import React from "react";
import _ from "lodash";
import styles from "./FoundUserSkeleton.module.css";

const FoundUserSkeleton = () => {
  return (
    <div className={styles["skeleton-container"]}>
      <div className={styles["content-wrapper"]}>
        <div className={styles["profile-picture"]}></div>
        <div className={styles["name"]}></div>
      </div>
    </div>
  );
};

export default FoundUserSkeleton;
