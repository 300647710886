import React from "react";
import "./index.css";
import { ReactComponent as ClosingIcon } from "./x-close-delete-svgrepo-com.svg";

const Error = ({ message, closingFunction }) => {
  return (
    <div className="error-container">
      <span>{message}</span>
      <div
        className="close-button-container"
        onClick={() => closingFunction(false)}
      >
        <ClosingIcon className="close-button" />
      </div>
    </div>
  );
};

export default Error;
