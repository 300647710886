import React, { useEffect, useState } from "react";
import styles from "./AssignPersonItem.module.css";
import DefaultPersonPicture from "./blank-profile-picture.webp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AssignPersonItem = ({
  person,
  index,
  faceId,
  setAssignTrigger,
  setAssignDropdown,
}) => {
  const [error, setError] = useState(false);
  const [personPicture, setPersonPicture] = useState("");
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const fetchPersonPicture = async () => {
    if (!person["picture_path"]) {
      return;
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/${person["person_id"]}/picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setPersonPicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const assignPersonToFace = async (personId) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/assign`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ person_id: personId, face_id: faceId }),
        }
      );
      setAssignTrigger((curr) => !curr);
      setAssignDropdown(false);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchPersonPicture();
  }, []);

  return (
    <div
      className={styles["person-to-assign-container"]}
      onClick={() => assignPersonToFace(person["person_id"])}
    >
      <div className={styles["person-image-container"]}>
        <img src={personPicture || DefaultPersonPicture} alt="person-picture" />
      </div>
      <div className={styles["person-name-container"]}>
        <span>{person["first_name"]} </span>
        <span>{person["last_name"]}</span>
      </div>
    </div>
  );
};

export default AssignPersonItem;
