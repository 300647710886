import React from "react";
import "./index.css";
import { useSearchParams } from "react-router-dom";

const PasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return <div>{searchParams.get("reset_token")}</div>;
};

export default PasswordReset;
