import React from "react";
import Navbar from "scenes/navbar";
import ProfilePicture from "./blank-profile-picture.webp";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./index.css";
import Error from "components/Error";
import ConfirmDelete from "components/ConfirmDelete";

const UserPage = () => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const params = useParams();
  const userId = params.userId;
  const [friendsRequestStatus, SetFriendsRequestStatus] = useState(false);
  const [friendshipInfo, setFriendshipInfo] = useState({});
  const [isFriend, setIsFriend] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  const [photoCount, setPhotoCount] = useState(0);
  const [profilePicture, setProfilePicture] = useState("");
  const [albums, setAlbums] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const addFriend = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}`,
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status == 200) {
        SetFriendsRequestStatus(true);
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const removeFriend = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const cancelFriendRequest = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}/cancel`,
        {
          mode: "cors",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      SetFriendsRequestStatus(false);
      return await res.json();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchProfilePicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${userId}/profile_picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setProfilePicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${userId}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await res.json();
      console.log(response);
      setProfileInfo(response["user"]);
      setAlbums(response["albums"]);
      setPhotoCount(response["photo_count"]);
      setFriendshipInfo(response["friendship"]);
      if (response["user"]["picture_path"]) {
        fetchProfilePicture(response["user"]["picture_path"]);
      }
      console.log(response);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [friendsRequestStatus]);

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      {confirmRemove && (
        <ConfirmDelete
          text={"Do you want to remove this person from friends?"}
          setIsOpen={setConfirmRemove}
          functionAfterConfirm={removeFriend}
        />
      )}
      <div className="user-page-container">
        <div
          style={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src={profilePicture || ProfilePicture}
              alt="Profile Picture"
              style={{ height: "350px", width: "350px" }}
            />
          </div>
          <p style={{ fontSize: "25px", marginTop: "14px" }}>Imię Nazwisko</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "350px",
            }}
          >
            <p style={{ fontSize: "28px" }}>General Informations</p>
            <table>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>Name:</td>
                <td>{profileInfo["username"]}</td>
              </tr>
              <tr>
                <td style={{ width: "150px", height: "20px" }}>Created At:</td>
                <td>
                  {new Date(profileInfo["created_at"]).toLocaleDateString()}
                </td>
              </tr>
            </table>
            <p style={{ fontSize: "28px" }}>About Me</p>
            <div>
              <p>{profileInfo["about_me"]}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "14px",
              justifyContent: "space-between",
            }}
          >
            <div></div>
          </div>
        </div>
        <div className="actions-card">
          <div className="card-item">
            <button onClick={() => navigate(`/chat/${userId}`)}>
              Send a message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserPage;
