import CurrentPageInfo from "components/CurrentPageInfo";
import Error from "components/Error";
import NoResults from "components/NoResults";
import Footer from "components/footer";
import GalleryItem from "components/galleryItem";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar";

const Album = () => {
  const params = useParams();
  const albumId = params.albumId;
  const [filenames, setFilenames] = useState([]);
  const [photoCount, setPhotoCount] = useState(0);
  const token = useSelector((state) => state.token);
  const [albumName, setAlbumName] = useState("");
  const [isAlbumNotEmpty, setIsAlbumNotEmpty] = useState(true);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setPhotoCount(0);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}/photos`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const album = await res.json();
      console.log(album);
      setAlbumName(album["name"]);
      if (!album["photos"]) {
        setIsAlbumNotEmpty(false);
      } else {
        setPhotoCount(album["count"]);
        setFilenames(album["photos"]);
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <CurrentPageInfo pageName={"Album: " + albumName} />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginLeft: "12px",
          width: "100%",
          height: "100%",
          paddingLeft: "50px",
        }}
      >
        {isAlbumNotEmpty ? (
          <>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {filenames.map((filename) => (
                <a
                  key={filename}
                  href={`${process.env.PUBLIC_URL}/photoPage/${filename}`}
                  style={{
                    textDecoration: "inherit",
                    color: "inherit",
                    cursor: "auto",
                  }}
                >
                  <GalleryItem imageFilename={filename} />
                </a>
              ))}
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoResults
              primaryText="You didn't add any photos yet..."
              secondaryText="Do it by clicking a button under an image"
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Album;
