import React, { useState, useMemo, useCallback, useRef } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
} from "@react-google-maps/api";
import "./index.css";

const Map = ({ setCoordinates }) => {
  const center = useMemo(() => ({ lat: 52.2297, lng: 21.0122 }), []);
  const [marker, setMarker] = useState({});
  //   const LatLngLiteral = google.maps.LatLngLiteral;
  //   const MapOptions = google.maps.MapOptions;
  const onMapClick = (e) => {
    setMarker(() => ({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }));
    setCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="map-container">
        <GoogleMap
          zoom={10}
          center={center}
          mapContainerClassName="map-container"
          onClick={onMapClick}
          options={{ streetViewControl: false }}
        >
          <Marker
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;
