import React, { useEffect, useState } from "react";
import RegularNavbar from "./RegularNavbar";
import NavbarMobile from "./NavbarMobile";

const Navbar = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 900px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 900px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  if (!matches) {
    return <NavbarMobile />;
  } else {
    return <RegularNavbar />;
  }
};

export default Navbar;
