import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import ReactSlider from "react-slider";
import Navbar from "scenes/navbar";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import "./index.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CurrentPageInfo from "components/CurrentPageInfo";
import FoundImage from "components/foundImage";
import NoResults from "components/NoResults";

const PhotoGeoSearch = () => {
  const regExp = /\(([^)]+)\)/;
  const center = useMemo(() => ({ lat: 52.2297, lng: 21.0122 }), []);
  const [searchParams, setSearchParams] = useSearchParams({
    lng: null,
    lat: null,
    radius: 3000,
  });
  const lng = parseFloat(searchParams.get("lng"));
  const lat = parseFloat(searchParams.get("lat"));
  const radius = parseFloat(searchParams.get("radius"));
  const [libraries] = useState(["places"]);
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");
  const [noPhotosFound, setNoPhotosFound] = useState(false);
  const [imagesFound, setImagesFound] = useState([]);
  const navigate = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCFBfoJFkHuYneNCu-a0QELQIrr9eB_Sio",
    libraries,
  });

  const onMapClick = (e) => {
    setSearchParams(
      (curr) => {
        curr.set("lat", parseFloat(e.latLng.lat()));
        curr.set("lng", parseFloat(e.latLng.lng()));
        return curr;
      },
      { replace: true }
    );
  };

  const searchImages = async () => {
    try {
      setImagesFound([]);
      if (!lng || !lat) {
        return "No location Chosen";
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/photos/geolocation/?lat=${lat}&lng=${lng}&radius=${radius}`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status !== 200) {
        setNoPhotosFound(true);
        return;
      } else {
        setNoPhotosFound(false);
      }
      const photos = await res.json();
      console.log(photos);
      photos?.map(async (photo) => {
        await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/thumbnail/${photo["aws_filename"]}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => response.blob())
          .then((blob) =>
            setImagesFound((curr) => [
              ...curr,
              { Url: URL.createObjectURL(blob), informations: photo },
            ])
          );
      });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (lat && lng) {
      searchImages();
    } else {
      setSearchParams(
        (curr) => {
          curr.set("lng", center.lng);
          curr.set("lat", center.lat);
          return curr;
        },
        { replace: true }
      );
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="page-container">
        <CurrentPageInfo
          pageName="Photo GeoSearch"
          error={error}
          setError={setError}
        />
        <div
          style={{
            height: "100%",
            width: "100vw",
            marginLeft: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>Select area to search in:</div>
          <div style={{ height: "70vh", width: "100%" }}>
            {isLoaded ? (
              <>
                <div
                  className="map-container"
                  style={{ height: "100%", width: "100%" }}
                >
                  <GoogleMap
                    zoom={10}
                    center={center}
                    onClick={onMapClick}
                    mapContainerClassName="map-container"
                    options={{ streetViewControl: false }}
                  >
                    <Marker
                      position={{
                        lat: lat,
                        lng: lng,
                      }}
                    />
                    <MarkerClusterer>
                      {imagesFound &&
                        ((clusterer) =>
                          imagesFound.map((photoWrapper) => {
                            if (
                              !photoWrapper["informations"]["photo_location"]
                            ) {
                              return "No Location";
                            }
                            const cordsArray = regExp
                              .exec(
                                photoWrapper["informations"]["photo_location"]
                              )[1]
                              .split(" ");
                            return (
                              <Marker
                                options={{
                                  icon: {
                                    url: photoWrapper["Url"],
                                    scaledSize: { width: 50, height: 50 },
                                  },
                                }}
                                key={photoWrapper["informations"]["photo_id"]}
                                position={{
                                  lat: parseFloat(cordsArray[1]),
                                  lng: parseFloat(cordsArray[0]),
                                }}
                                onClick={() =>
                                  navigate(
                                    `/photoPage/${photoWrapper["informations"]["aws_filename"]}`
                                  )
                                }
                                clusterer={clusterer}
                              />
                            );
                          }))}
                    </MarkerClusterer>
                    <Circle
                      center={{
                        lat: lat,
                        lng: lng,
                      }}
                      onClick={onMapClick}
                      radius={radius}
                      options={{ strokeColor: "#ff0000" }}
                    />
                  </GoogleMap>
                </div>

                <div>
                  <ReactSlider
                    className="customSlider"
                    thumbClassName="customSlider-thumb"
                    trackClassName="customSlider-track"
                    defaultValue={3000}
                    min={1000}
                    max={50000}
                    value={radius}
                    onChange={(value) =>
                      setSearchParams(
                        (curr) => {
                          curr.set("radius", value);
                          return curr;
                        },
                        { replace: true }
                      )
                    }
                  />
                </div>
                <button
                  className="search-button"
                  onClick={() => searchImages()}
                >
                  Search
                </button>
                {console.log(noPhotosFound)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px",
                    marginBottom: "30px",
                  }}
                >
                  {console.log(imagesFound)}
                  {imagesFound.length ? (
                    imagesFound?.map((photo) => {
                      return <FoundImage photo={photo} type="geo" />;
                    })
                  ) : noPhotosFound ? (
                    <NoResults
                      primaryText="No images found in this range"
                      redirectToForum={true}
                    />
                  ) : (
                    <div>{console.log("here")}</div>
                  )}
                </div>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoGeoSearch;
