import React from "react";
import Navbar from "scenes/navbar";
import FamilyPhoto from "./family_photo.jfif";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Error from "components/Error";
import Footer from "components/footer";

const HomePage = () => {
  const [missingDataPhotos, setMissingDataPhotos] = useState([]);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#eff7ed",
        height: "3000px",
        position: "relative",
      }}
    >
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div
        style={{
          height: "400px",
          marginBottom: "20px",
          backgroundImage: `url(${FamilyPhoto})`,
          backgroundPosition: "25% 25%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "auto auto auto",
          }}
        >
          <div
            style={{ backgroundColor: "pink", width: "auto", height: "600px" }}
          ></div>
          <div
            style={{ backgroundColor: "pink", width: "auto", height: "auto" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "30px",
                  width: "100%",
                  height: "40px",
                  marginTop: "8px",
                }}
              >
                Add Missing Data
              </div>
              <div></div>
            </div>
          </div>
          <div
            style={{ backgroundColor: "pink", width: "auto", height: "600px" }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
