import React from "react";
import styles from "./PersonItemSkeleton.module.css";

const PersonItemSekeleton = () => {
  return (
    <div className={styles["skeleton-container"]}>
      <div className={styles["content-wrapper"]}>
        <div className={styles["image"]}></div>
        <div className={styles["name"]}></div>
        <div className={styles["dots"]}></div>
      </div>
    </div>
  );
};

export default PersonItemSekeleton;
