import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import "./index.css";
import Error from "components/Error";

const Story = () => {
  const params = useParams();
  const storyId = params.storyId;
  const token = useSelector((state) => state.token);
  const [story, setStory] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const getStoryInfo = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/story/${storyId}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStory((await res.json())["story"]);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (storyId) {
      getStoryInfo();
    }
  }, []);
  return (
    <>
      <Navbar />
      <div
        style={{
          paddingLeft: "180px",
          paddingBottom: "5px",
          marginBottom: "40px",
          fontSize: "30px",
          color: "#282d2e",
          borderBottom: "solid 1px #d1e6fa",
          display: "flex",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <p>Story</p>
        {error && <Error message={error} closingFunction={setError} />}
      </div>
      <div className="story-page-container">
        {story && (
          <>
            <p>{story?.["title"]}</p>
            <p>{story?.["content"]}</p>
            <p>{story?.["creator"]}</p>
            {story["array"]?.map((person) => {
              return (
                <div
                  onClick={() => navigate(`/personPage/${person["person_id"]}`)}
                  className="mentioned-container"
                >
                  <span>{person["first_name"]} </span>
                  <span>{person["last_name"]}</span>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default Story;
