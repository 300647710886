import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import "./index.css";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import ReactSlider from "react-slider";
import { setFaceSearchLocation } from "state";
import Error from "components/Error";
import CurrentPageInfo from "components/CurrentPageInfo";
import FoundImage from "components/foundImage";

const SearchFaces = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const faceId = searchParams.get("faceId");
  const myPhotosOnly = searchParams.get("myPhotosOnly");
  const awsFilename = searchParams.get("awsFilename");
  const [sliderState, setSliderState] = useState(myPhotosOnly);
  const regExp = /\(([^)]+)\)/;
  const center = useMemo(() => ({ lat: 52.2297, lng: 21.0122 }), []);
  const [libraries] = useState(["places"]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [marker, setMarker] = useState({});
  const [lngLat, setLngLat] = useState({});
  const [circleRadius, setCircleRadius] = useState(3000);
  const [searchOnlyUserPhotos, setSearchOnlyUserPhotos] = useState(false);
  const [currentSearchedPhotoUrl, setCurrentSearchedPhotoUrl] = useState("");
  const [currentSearchedPhoto, setCurrentSearchedPhoto] = useState({});
  const [faceInfo, setFaceInfo] = useState([]);
  const [simmilarFaces, setSimmilarFaces] = useState([]);
  const [mapOpen, setMapOpen] = useState(false);
  const [noPhotos, setNoPhotos] = useState(false);
  const [error, setError] = useState("");
  const faceSearchLocation = useSelector((state) => state.faceSearchLocation);
  const navigate = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCFBfoJFkHuYneNCu-a0QELQIrr9eB_Sio",
    libraries,
  });

  const onMapClick = (e) => {
    setMarker(() => ({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }));
    setCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const setCoordinates = (coords) => {
    setLngLat(coords);
  };

  const saveLocationToState = () => {
    dispatch(setFaceSearchLocation([lngLat, circleRadius]));
  };

  const fetchOriginalInfo = async () => {
    try {
      if (awsFilename && faceId) {
        const res = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person/face_id/${faceId}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          setCurrentSearchedPhoto(await res.json());
        }

        await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/thumbnail/${awsFilename}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => response.blob())
          .then((blob) =>
            setCurrentSearchedPhotoUrl((curr) => URL.createObjectURL(blob))
          );
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    setSimmilarFaces([]);
    fetchOriginalInfo();

    const fetchFacesWithoutLocation = async () => {
      try {
        if (faceId) {
          const simmilar_faces_res = await fetch(
            `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/faces/${faceId}`,
            {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ own_photos: myPhotosOnly }),
            }
          );
          if (simmilar_faces_res.status === 204) {
            setNoPhotos(true);
            return;
          }
          const simmilar_faces = await simmilar_faces_res.json();
          if (simmilar_faces_res.status === 200) {
            setNoPhotos(false);
            simmilar_faces["data"].map(async (photo) => {
              await fetch(
                `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/thumbnail/${photo["aws_filename"]}`,
                {
                  method: "GET",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
                .then((response) => response.blob())
                .then((blob) =>
                  setSimmilarFaces((curr) => [
                    ...curr,
                    { Url: URL.createObjectURL(blob), informations: photo },
                  ])
                );
            });
          }
        }
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    const fetchFacesWithLocation = async () => {
      try {
        if (myPhotosOnly) {
          const res = await fetch(
            `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/faces/${faceId}`,
            {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                own_photos: myPhotosOnly,
                geolocation_search: true,
                lat: faceSearchLocation[0]["lat"],
                lng: faceSearchLocation[0]["lng"],
                radius: faceSearchLocation[1],
              }),
            }
          );
          if (res.status === 204) {
            setNoPhotos(true);
            return;
          }
          if (res.status !== 200) {
            return;
          }
          setNoPhotos(false);
          const backendData = await res.json();
          backendData?.["data"]?.map(async (photo) => {
            await fetch(
              `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/photo/thumbnail/${photo["aws_filename"]}`,
              {
                method: "GET",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
              .then((response) => response.blob())
              .then((blob) =>
                setSimmilarFaces((curr) => [
                  ...curr,
                  { Url: URL.createObjectURL(blob), informations: photo },
                ])
              );
          });
        }
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    if (faceSearchLocation) {
      fetchFacesWithLocation();
    } else {
      fetchFacesWithoutLocation();
    }
  }, [faceSearchLocation, myPhotosOnly, faceId, awsFilename]);

  return (
    <>
      <Navbar />

      <div className="page-container">
        <CurrentPageInfo
          pageName="Face Search"
          error={error}
          setError={setError}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "30px",
          }}
        >
          <div className="page-divider">
            <div className="search-parameters">
              <div className="settings-container">
                <div className="location-cluster">
                  <button
                    onClick={() => setMapOpen(true)}
                    className="location-map-button"
                  >
                    {simmilarFaces[0] ? "See map" : "Choose Location"}
                  </button>
                  {faceSearchLocation ? (
                    <div className="location-info">
                      <p className="location-set-text">Photo location Set</p>
                      <button
                        onClick={() => dispatch(setFaceSearchLocation(null))}
                        className="clear-location-button"
                      >
                        Clear Location
                      </button>
                    </div>
                  ) : (
                    <p className="no-location-set-text">Location is not set</p>
                  )}
                </div>
                <button
                  onClick={() => navigate("/photoGallery")}
                  className="face-choice-button"
                >
                  Choose Face to search
                </button>
                <div className="switch-container">
                  <p>Search in my photos only</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={myPhotosOnly}
                      onChange={(e) => {
                        e.preventDefault();
                        setSearchParams((params) => {
                          params.set("myPhotosOnly", e.target.checked);
                          return params;
                        });
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="photos-and-original">
              <div className="original-container">
                <div
                  className={
                    currentSearchedPhotoUrl
                      ? "thumbnail-container"
                      : "no-face-chosen-yet"
                  }
                >
                  {currentSearchedPhotoUrl ? (
                    <img
                      src={currentSearchedPhotoUrl}
                      alt="Searched Tumbnail"
                    />
                  ) : (
                    <p>No face chosen yet</p>
                  )}
                </div>
                {currentSearchedPhotoUrl && (
                  <div className="searched-info-container">
                    <p>
                      Person's Name:{" "}
                      <span>
                        {currentSearchedPhoto["who-is-it"] || "Not known"}
                      </span>
                    </p>
                    <p>
                      Description:{" "}
                      <span>
                        {currentSearchedPhoto["description"] ||
                          " No description"}
                      </span>
                    </p>
                    <p>
                      When Taken:{" "}
                      <span>
                        {currentSearchedPhoto["when-taken"] || "No date"}
                      </span>
                    </p>
                    <p>
                      Photo Location:{" "}
                      <span>
                        {currentSearchedPhoto["astext"] || "No location"}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="found-container">
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  {noPhotos ? (
                    <div
                      style={{
                        marginTop: "25px",
                        height: "150px",
                        width: "450px",
                        border: "2px dashed red",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "20px",
                      }}
                    >
                      No photos found. Try expanding your criteria
                    </div>
                  ) : simmilarFaces ? (
                    simmilarFaces?.map((photo) => {
                      return (
                        <FoundImage
                          photo={photo}
                          type={
                            Object.keys(lngLat).length > 0 ? "geo" : "normal"
                          }
                        />
                      );
                    })
                  ) : (
                    <div>No images to show</div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}></div>
          </div>
          {mapOpen &&
            (isLoaded ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "rgba(0, 0, 0, 0.85)",
                  }}
                >
                  <div className="map-container">
                    <GoogleMap
                      zoom={10}
                      center={center}
                      onClick={onMapClick}
                      mapContainerClassName="map-container"
                      options={{ streetViewControl: false }}
                    >
                      <Marker
                        position={{
                          lat: faceSearchLocation?.[0]?.["lat"],
                          lng: faceSearchLocation?.[0]?.["lng"],
                        }}
                      />
                      <MarkerClusterer>
                        {simmilarFaces &&
                          ((clusterer) =>
                            simmilarFaces.map((photoWrapper) => {
                              if (
                                !photoWrapper["informations"]["photo_location"]
                              ) {
                                return "No Location";
                              }
                              const cordsArray = regExp
                                .exec(
                                  photoWrapper["informations"]["photo_location"]
                                )[1]
                                .split(" ");
                              return (
                                <Marker
                                  key={photoWrapper["informations"]["photo_id"]}
                                  position={{
                                    lat: parseFloat(cordsArray[1]),
                                    lng: parseFloat(cordsArray[0]),
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/photoPage/${photoWrapper["informations"]["aws_filename"]}/?faceId=${photoWrapper["informations"]["face_id"]}&Box0=${photoWrapper["informations"]["position_on_image"][0]}&Box1=${photoWrapper["informations"]["position_on_image"][1]}&Box2=${photoWrapper["informations"]["position_on_image"][2]}&Box3=${photoWrapper["informations"]["position_on_image"][3]}`
                                    )
                                  }
                                  clusterer={clusterer}
                                />
                              );
                            }))}
                        {}
                      </MarkerClusterer>
                      <Circle
                        center={{
                          lat: marker.lat,
                          lng: marker.lng,
                        }}
                        onClick={onMapClick}
                        radius={circleRadius}
                        options={{ strokeColor: "#ff0000" }}
                      />
                    </GoogleMap>
                    <button
                      style={{ marginTop: "30px" }}
                      onClick={() => saveLocationToState()}
                    >
                      Set Location
                    </button>
                  </div>
                  <div>
                    <ReactSlider
                      className="customSlider"
                      thumbClassName="customSlider-thumb"
                      trackClassName="customSlider-track"
                      defaultValue={3000}
                      min={1000}
                      max={50000}
                      value={circleRadius}
                      onChange={(value) => setCircleRadius(value)}
                    />
                  </div>
                  <div
                    style={{ position: "absolute", right: "50px", top: "50px" }}
                  >
                    <button onClick={() => setMapOpen(false)}>Close Map</button>
                  </div>
                </div>
              </>
            ) : (
              <div>Loading...</div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SearchFaces;
