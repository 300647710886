import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FriendItem.module.css";
import { useSelector } from "react-redux";
import DefaultProfilePicture from "./blank-profile-picture.webp";
import ConfirmDelete from "components/ConfirmDelete";

const FriendItem = ({
  friend,
  index,
  chooseAdmin,
  albumId,
  chooseViewer,
  removeFriend,
}) => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState("");
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");
  const [confirmRemove, setConfirmRemove] = useState(false);

  const handleClick = async () => {
    if (chooseAdmin === "true") {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}/administrator/${friend["friend_id"]}`,
        {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } else if (chooseViewer === "true") {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}/viewer/${friend["friend_id"]}`,
        {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
    } else {
      navigate(`/userPage/${friend["friend_id"]}`);
    }
  };

  const fetchProfilePicture = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/${friend["friend_id"]}/profile_picture?isThumbnail=1`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setProfilePicture(URL.createObjectURL(blob));
        });
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    console.log(friend);
    if (friend["picture_path"]) {
      fetchProfilePicture();
    }
  }, []);

  return (
    <>
      {confirmRemove && (
        <ConfirmDelete
          functionAfterConfirm={removeFriend}
          setIsOpen={setConfirmRemove}
          text={`Do you want to remove ${friend["username"]} from friends?`}
          funcParams={[friend["user_id"]]}
        />
      )}
      <div className={styles["friend-card"]} onClick={() => handleClick()}>
        <img
          src={profilePicture || DefaultProfilePicture}
          alt={`${friend["username"]}'s profile`}
          className={styles["profile-picture"]}
        />
        <div className={styles["friend-details"]}>
          <div className={styles["friend-name"]}>{friend["username"]}</div>
          <div className={styles["dropdown"]}>
            <button className={styles["dropdown-btn"]}>Actions</button>
            <div className={styles["dropdown-content"]}>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/userPage/${friend["friend_id"]}`);
                }}
              >
                Access Profile
              </a>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmRemove(true);
                }}
              >
                Remove Friend
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendItem;
