import CurrentPageInfo from "components/CurrentPageInfo";
import Error from "components/Error";
import NoResults from "components/NoResults";
import AlbumItem from "components/albumItem";
import Footer from "components/footer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "scenes/navbar";

const Albums = () => {
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [albums, setAlbums] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/albums`,
          {
            mode: "cors",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status !== 200) {
          return;
        }
        const fetched_albums = await res.json();
        setAlbums((curr) => [...curr, ...fetched_albums["data"]]);
      } catch ({ name, message }) {
        console.log(message);
        setError(message);
      }
    };
    setAlbums([]);
    fetchAlbums();
  }, []);
  return (
    <>
      <Navbar />
      <div className="page-container">
        <CurrentPageInfo pageName="Albums" error={error} setError={setError} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {albums.length === 0 ? (
            <NoResults
              primaryText="You didn't add any albums yet"
              secondaryText="To do it click the button below"
            />
          ) : (
            <table
              style={{
                border: "1px solid #d1e6fa",
                width: "70%",
                padding: "10px",
                borderCollapse: "collapse",
                color: "#282d2e",
              }}
            >
              <thead style={{ background: "#e3a9a6" }}>
                <tr style={{ height: "70px", borderLeft: "1px solid #d1e6fa" }}>
                  <th style={{ borderRight: "1px solid #d1e6fa" }}>No</th>
                  <th style={{ borderRight: "1px solid #d1e6fa" }}>Name</th>
                  <th style={{ borderRight: "1px solid #d1e6fa" }}>
                    Number of Photos
                  </th>
                  <th style={{ borderRight: "1px solid #d1e6fa" }}>
                    Who Can View
                  </th>
                  <th
                    style={{ borderRight: "1px solid #d1e6fa", width: "170px" }}
                  >
                    Menage Your Album
                  </th>
                </tr>
              </thead>
              <tbody>
                {albums?.map((album, index) => {
                  return <AlbumItem album={album} index={index} />;
                })}
              </tbody>
            </table>
          )}

          <button
            style={{
              width: "210px",
              height: "60px",
              background: "#e3bea6",
              border: "none",
              cursor: "pointer",
              fontSize: "20px",
              color: "#282d2e",
            }}
            onClick={() => navigate("/createAlbum")}
          >
            Create new album
          </button>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Albums;
