import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PersonItem from "components/personItem";
import styles from "./PeopleSearch.module.css";
import _ from "lodash";
import PersonItemSekeleton from "skeletons/personItemSkeleton";
import EmptyListInfo from "components/emptyListInfo";
import CurrentPageInfo from "components/CurrentPageInfo";
import NoResults from "components/NoResults";

const PeopleSearch = () => {
  const PLACEHOLDER_COUNT = 8;
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });
  const q = searchParams.get("q");
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");
  const [emptyResponse, setEmptyResponse] = useState(false);
  const token = useSelector((state) => state.token);
  const [foundPeople, setFoundPeople] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const LIMIT = 10;

  const observer = useRef();
  const lastBookElementRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setOffset((curr) => curr + LIMIT);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const searchPeople = async () => {
    try {
      setFoundPeople([]);
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/people?query=${q}&offset=${offset}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setLoading(false);
        setEmptyResponse(true);
        return "No people found";
      } else {
        const temp = await res.json();
        setEmptyResponse(false);
        setLoading(false);
        setFoundPeople(temp);
      }
    } catch ({ name, message }) {
      setEmptyResponse(true);
      setLoading(false);
      console.log(message);
      setError(message);
    }
  };

  const fetchMoreRecords = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/people?query=${q}&offset=${offset}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setLoading(false);
        setEmptyResponse(true);
        return "No people found";
      } else {
        const temp = await res.json();
        setEmptyResponse(false);
        setLoading(false);
        setFoundPeople((curr) => [...curr, ...temp]);
      }
    } catch ({ name, message }) {
      setEmptyResponse(true);
      setLoading(false);
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (q) {
      searchPeople();
    }
  }, []);

  useEffect(() => {
    if (offset !== 0) {
      fetchMoreRecords();
    }
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [q]);

  return (
    <>
      <Navbar />
      <CurrentPageInfo pageName="People Search" />
      {console.log(foundPeople)}
      <div className={styles["people-search-page-container"]}>
        <div className={styles["input-container"]}>
          <input
            className={styles["user-input"]}
            type="text"
            value={q}
            placeholder="Search people..."
            onChange={(e) =>
              setSearchParams(
                (curr) => {
                  curr.set("q", e.target.value);
                  return curr;
                },
                { replace: true }
              )
            }
          />
          <button
            className={styles["search-button"]}
            onClick={() => searchPeople()}
          >
            Search
          </button>
        </div>
        <div>
          {foundPeople &&
            foundPeople.map((person, index) => {
              if (index + 1 === offset + LIMIT) {
                return (
                  <PersonItem
                    ref={lastBookElementRef}
                    searched={true}
                    person={person}
                    index={index}
                  />
                );
              } else {
                return (
                  <PersonItem searched={true} person={person} index={index} />
                );
              }
            })}
          {emptyResponse && (
            <NoResults
              primaryText={"No people found!"}
              secondaryText={"Try alternate spelling and try again"}
            />
          )}
          {loading &&
            _.range(PLACEHOLDER_COUNT).map((index) => {
              return <PersonItemSekeleton key={index} />;
            })}
        </div>
      </div>
    </>
  );
};

export default PeopleSearch;
