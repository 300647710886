import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import Background from "./register_background.jpg";
import Error from "components/Error";
import DropzoneComponent from "./DropzoneComponent";
import axios from "axios";

const registerSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("Invalid Email").required("required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .max(40, "Password can not be longer than 40 characters")
    .required("required"),
  username: yup
    .string()
    .min(4, "Username must be at least 4 characters long")
    .max(40, "Username can not be longer than 40 characters")
    .required("required"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  username: "",
};

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [picture, setPicture] = useState([]);

  const register = async (values, onSubmitProps, setErrors) => {
    console.log(onSubmitProps);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/register`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            email: values["email"],
            password: values["password"],
            first_name: values["firstName"],
            last_name: values["lastName"],
            username: values["username"],
          }),
        }
      );
      const fullRes = await res.json();
      console.log(fullRes);
      if (res.status === 409) {
        if (fullRes === "Account with that email already exists") {
          onSubmitProps.setErrors({
            email: "Account with that email already exists",
          });
          return 1;
        }
        if (fullRes === "Account with that username already exists") {
          onSubmitProps.setErrors({
            username: "Account with that username already exists",
          });
          return 1;
        }
      }

      // if (picture?.name?.length) {
      //   const token = fullRes["token"];
      //   const data = new FormData();
      //   data.append("file", picture);
      //   await axios
      //     .post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/user/token/profile_picture", data, {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         cors: true,
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //     .catch((e) => {
      //       console.log(e.response);
      //     });
      // }
      console.log(fullRes);
      if (res.status === 200) {
        navigate("/");
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps, { setErrors }) => {
    await register(values, onSubmitProps, setErrors);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {error && <Error message={error} closingFunction={setError} />}
      <div
        style={{ background: "white", padding: "40px", borderRadius: "5px" }}
      >
        <Formik
          onSubmit={register}
          initialValues={initialValuesRegister}
          validationSchema={registerSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "grid",
                  gap: "30px",
                  gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "500px",
                }}
              >
                <TextField
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={
                    Boolean(touched.firstName) && Boolean(errors.firstName)
                  }
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={Boolean(touched.username) && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 4" }}
                />
                <div style={{ gridColumn: "span 4" }}>
                  <DropzoneComponent setProfilePicture={setPicture} />
                </div>
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4", width: "100%" }}
                />
                <TextField
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4", width: "100%" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    gridColumn: "span 4",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      width: "80%",
                      margin: "2rem 0",
                      padding: "1rem",
                      backgroundColor: "silver",
                      color: "grey",
                    }}
                  >
                    REGISTER
                  </button>
                  <Typography
                    onClick={() => {
                      navigate("/login");
                      resetForm();
                    }}
                    sx={{
                      textDecoration: "underline",
                      color: "grey",
                      "&:hover": {
                        cursor: "pointer",
                        color: "blue",
                      },
                    }}
                  >
                    Already have an account? Log in here.
                  </Typography>
                </Box>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterPage;
