import Error from "components/Error";
import EmptyListInfo from "components/emptyListInfo";
import FoundUserItem from "components/foundUserItem";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import FoundUserSkeleton from "skeletons/foundUserSkeleton";
import _ from "lodash";
import styles from "./UserSearch.module.css";
import CurrentPageInfo from "components/CurrentPageInfo";
import NoResults from "components/NoResults";

const UserSearch = () => {
  const PLACEHOLDER_COUNT = 6;
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });
  const query = searchParams.get("q");
  const token = useSelector((state) => state.token);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [noneFound, setNoneFound] = useState(false);
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      setLoading(true);
      if (!query) {
        setLoading(false);
        return "No input";
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/search/users?query=${query}`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setNoneFound(true);
        setLoading(false);
      } else {
        setNoneFound(false);
        setLoadedUsers(await res.json());
        setLoading(false);
      }
    } catch ({ name, message }) {
      setLoading(false);
      console.log(message);
      setError(message);
    }
  };

  const addFriend = async (userId) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}`,
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const removeFriend = async (userId) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (query) {
      getUsers();
    }
  }, []);

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      {console.log(loadedUsers)}
      <div className="page-container">
        <CurrentPageInfo
          pageName="User Search"
          error={error}
          setError={setError}
        />
        <div
          style={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <div className={styles["input-container"]}>
            <input
              placeholder="Search Users..."
              className={styles["user-input"]}
              value={query}
              onChange={(e) =>
                setSearchParams(
                  (curr) => {
                    curr.set("q", e.target.value);
                    return curr;
                  },
                  { replace: true }
                )
              }
            />
            <button
              className={styles["search-button"]}
              onClick={() => getUsers()}
            >
              Search
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            flexDirection: "column",
          }}
        >
          {loading ? (
            _.range(PLACEHOLDER_COUNT).map((index) => {
              return <FoundUserSkeleton key={index} />;
            })
          ) : noneFound ? (
            <NoResults
              primaryText={"No users found"}
              secondaryText={"Check your spelling and try again"}
            />
          ) : (
            loadedUsers.map((user) => {
              console.log(user);
              return (
                <FoundUserItem
                  user={user}
                  removeFriend={removeFriend}
                  addFriend={addFriend}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default UserSearch;
