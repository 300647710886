import React from "react";
import Background from "./photo_album_background.jpg";
import { useState } from "react";
import LoginForm from "./Form";
import { useNavigate } from "react-router-dom";
import styles from "./LoginPage.module.css";

const LoginPage = () => {
  const [isLogin, setisLogin] = useState(true);
  const navigate = useNavigate();
  if (isLogin) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <div
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
          }}
        ></div>
        <div style={{ width: "700px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "35px",
              flexDirection: "row",
            }}
          >
            {/* <div
              width="100%"
              backgroundColor="grey"
              p="1rem 6%"
              textAlign="center"
            >
              <Typography color="yellow">Family Photos</Typography>
            </div> */}
            <div>
              <p
                style={{
                  marginBottom: "1.5rem",
                  height: "20%",
                  padding: "30px",
                  textAlign: "center",
                }}
              >
                Welcome to Family Photos!!!
              </p>
              <div
                style={{
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoginForm />
              </div>
              <p
                className={styles["sign-up-text"]}
                onClick={() => navigate("/register")}
              >
                Don't have an account? Sign up here!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return undefined;
  }
};

export default LoginPage;
