import Error from "components/Error";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "scenes/navbar";

const CreateAlbum = () => {
  const [albumName, setAlbumName] = useState("");
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const createAlbum = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album`,
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ name: albumName }),
        }
      );
      navigate("/albums");
      return await res.json();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };
  return (
    <div>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ marginBottom: "20px" }}>Album Name</label>
          <input
            style={{ width: "230px", height: "30px", fontSize: "16px" }}
            type="text"
            onChange={(e) => setAlbumName(e.target.value)}
          />
          <button
            type="submit"
            style={{ height: "30px", fontSize: "16px", marginTop: "20px" }}
            onClick={() => createAlbum()}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAlbum;
