import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as AddIcon } from "../../assets/icons/plus-large-svgrepo-com.svg";

const AlbumsDropdown = ({ photoFilename, isMultipleImages = false }) => {
  const token = useSelector((state) => state.token);
  const user_id = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [albums, setAlbums] = useState([]);
  const fetchAlbums = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/albums/?aws_filename=${photoFilename}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status !== 200) {
      return;
    }
    const albums = (await res.json())["data"];
    setAlbums(albums);
  };

  const handleChange = async (checked, albumId) => {
    if (isMultipleImages) {
    } else {
      console.log(checked, albumId);
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/album/${albumId}/${photoFilename}`,
        {
          method: checked ? "POST" : "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  useEffect(() => {
    fetchAlbums();
  }, []);

  return (
    <>
      {console.log(albums)}
      <div style={{ position: "relative" }}>
        <button
          className="my-button"
          onClick={() => setIsOpen((curr) => !curr)}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            Add to album
            <span>
              <AddIcon />
            </span>
          </p>
        </button>

        {isOpen && (
          <div
            style={{
              position: "absolute",
              // top: "0",
              // left: "0",
              width: "200px",
            }}
          >
            {albums.map((album, index) => {
              console.log(album);
              return (
                <div
                  style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#7ccfc9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <span>{album["name"]}</span>
                  <input
                    type="checkbox"
                    name={album["album_id"]}
                    id={album["album_id"]}
                    defaultChecked={album["isinalbum"]}
                    style={{ width: "23px", height: "23px" }}
                    onChange={(e) => {
                      handleChange(e.target.checked, e.target.id);
                      setAlbums((curr) => {
                        curr[index]["isinalbum"] = !album["isinalbum"];
                        return curr;
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default AlbumsDropdown;
