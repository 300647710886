import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import Field from "../../components/field/Field";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-svgrepo-com.svg";
import CurrentPageInfo from "components/CurrentPageInfo";
import General from "./General";
import Security from "./Security";
import Privacy from "./Privacy";
import Notifications from "./Notifications";

const UserSettings = () => {
  const user = useSelector((state) => state.user); // This is user ID
  const token = useSelector((state) => state.token);
  const [currentCategory, setCurrentCategory] = useState("general");
  const [accountInfo, setAccountInfo] = useState({});
  const [editPassword, setEditPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formInfo, setFormInfo] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/account`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        setAccountInfo(data);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar />
      <CurrentPageInfo
        pageName="User Settings"
        error={error}
        setError={setError}
      />
      {console.log(accountInfo)}
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          paggingBottom: "100px",
          paddingBottom: "50px",
        }}
      >
        <div
          style={{
            width: "1000px",
            height: "800px",
            backgroundColor: "#d9fffe",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "250px",
              height: "100%",
              backgroundColor: "#96deff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
                boxSizing: "border-box",
                border:
                  currentCategory === "general"
                    ? "2px solid black"
                    : "2px solid rgba(186, 191, 191, 0.7)",
                cursor: "pointer",
                borderBottom:
                  !currentCategory === "general" ? "none" : "2px solid black", // Remove double border on the bottom
              }}
              onClick={() => setCurrentCategory("general")}
            >
              <p>General</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
                boxSizing: "border-box",
                border:
                  currentCategory === "security"
                    ? "2px solid black"
                    : "2px solid rgba(186, 191, 191, 0.7)",
                cursor: "pointer",
                borderBottom: "none", // Remove double border on the bottom
              }}
              onClick={() => setCurrentCategory("security")}
            >
              <p>Security</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
                boxSizing: "border-box",
                border:
                  currentCategory === "privacy"
                    ? "2px solid black"
                    : "2px solid rgba(186, 191, 191, 0.7)",
                cursor: "pointer",
                borderBottom: "none", // Remove double border on the bottom
              }}
              onClick={() => setCurrentCategory("privacy")}
            >
              <p>Privacy</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
                boxSizing: "border-box",
                border:
                  currentCategory === "notifications"
                    ? "2px solid black"
                    : "2px solid rgba(186, 191, 191, 0.7)",
                cursor: "pointer",
              }}
              onClick={() => setCurrentCategory("notifications")}
            >
              <p>Notifications</p>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            {currentCategory === "general" && (
              <General accountInfo={accountInfo} />
            )}
            {currentCategory === "security" && (
              <Security formInfo={formInfo} setFormInfo={setFormInfo} />
            )}
            {currentCategory === "privacy" && <Privacy />}
            {currentCategory === "notifications" && <Notifications />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
