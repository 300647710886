import CurrentPageInfo from "components/CurrentPageInfo";
import Error from "components/Error";
import NoResults from "components/NoResults";
import EmptyListInfo from "components/emptyListInfo";
import FriendItem from "components/friendItem";
import FriendRequestItem from "components/friendRequestItem";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "scenes/navbar";

const Friends = () => {
  const [searchParams] = useSearchParams();
  const chooseAdmin = searchParams.get("chooseAdmin");
  const chooseViewer = searchParams.get("chooseViewer");
  const albumId = searchParams.get("albumId");
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [friendRequests, setFriendRequests] = useState([]);
  const [friends, setFriends] = useState([]);
  const [error, setError] = useState("");
  const [emptyValue, setEmptyValue] = useState(false);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/friends`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setFriends((await res.json())["data"]);
        setEmptyValue(false);
      } else if (res.status === 204) {
        setEmptyValue(true);
      } else {
        setError("Something went wrong");
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/me/requests`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setFriendRequests((await res.json())["data"]);
        setEmptyValue(false);
      }
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const removeFriend = async (userId) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/friend/${userId}`,
        {
          mode: "cors",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFriendRequests();
  }, []);

  return (
    <>
      <Navbar />
      {error && <Error message={error} closingFunction={setError} />}
      {chooseAdmin && (
        <CurrentPageInfo
          pageName="Choose administrator"
          error={error}
          setError={setError}
        />
      )}
      {chooseViewer && (
        <CurrentPageInfo
          pageName="Choose viewer"
          error={error}
          setError={setError}
        />
      )}
      {!!(!chooseAdmin & !chooseViewer) && (
        <CurrentPageInfo pageName="Friends" error={error} setError={setError} />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {friendRequests?.map((request) => {
            return <FriendRequestItem request={request} />;
          })}
        </div>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {emptyValue ? (
            <NoResults
              primaryText={"You don't have any friends yet..."}
              secondaryText="You can add some by going "
              url={"/userSearch"}
            />
          ) : (
            friends?.map((friend, index) => {
              return (
                <FriendItem
                  friend={friend}
                  index={index}
                  chooseAdmin={chooseAdmin}
                  albumId={albumId}
                  chooseViewer={chooseViewer}
                  removeFriend={removeFriend}
                  style={{ backgroundColor: "#a9d8b8" }}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Friends;
