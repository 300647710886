import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddPhotos from "scenes/createFamily/addPhotos";
import Navbar from "scenes/navbar";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Error from "components/Error";
import GalleryItem from "components/galleryItem";
import Footer from "components/footer";
import CurrentPageInfo from "components/CurrentPageInfo";
import UploadButton from "assets/buttons/UploadButton";
import NoResults from "components/NoResults";

const Gallery = () => {
  const user_id = useSelector((state) => state.user);
  const [idArray, setIdArray] = useState([]);
  const token = useSelector((state) => state.token);
  const [photoUpload, setPhotoUpload] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loadingPhotos, setLoadingPhotos] = useState([]);
  const [isLastImageLoaded, setIsLastImageLoaded] = useState(false);
  const [imageFilenames, setImageFilenames] = useState([]);
  const [photoCount, setPhotoCount] = useState(0);
  const [checked, setChecked] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageNumber((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingPhotos]
  );

  const fetchPhotoCount = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/photos/count`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPhotoCount((await res.json())["count"]);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchImagesFilenames = async () => {
    setPhotos([]);
    setLoadingPhotos(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/${user_id}/photos?page_number=${pageNumber}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status !== 200) {
        return;
      }
      setLoadingPhotos(false);
      const temp = await res.json();
      return temp;
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  const fetchWrapper = async () => {
    if (pageNumber === 0) {
      setImageFilenames(await fetchImagesFilenames());
    } else {
      const temp = await fetchImagesFilenames();
      setImageFilenames((curr) => [...curr, ...temp]);
    }
  };

  useEffect(() => {
    fetchPhotoCount();
    fetchWrapper();
  }, []);

  useEffect(() => {
    if (pageNumber !== 0) {
      fetchWrapper();
    }
  }, [pageNumber]);

  return (
    <>
      <Navbar />
      <div className="page-container">
        {photoUpload && (
          <div
            style={{
              position: "fixed",
              zIndex: "8",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) =>
              e.currentTarget === e.target && setPhotoUpload(false)
            }
          >
            <button
              onClick={() => setPhotoUpload(false)}
              style={{
                position: "absolute",
                right: "67px",
                top: "32px",
                height: "33px",
                width: "94px",
                border: "none",
                backgroundColor: "#e35659",
                cursor: "pointer",
              }}
            >
              Close
            </button>
            <div style={{ cursor: "pointer" }}>
              <AddPhotos />
            </div>
          </div>
        )}
        <CurrentPageInfo pageName="Gallery" error={error} setError={setError} />
        <div className="gallery-container">
          <div className="buttons-container">
            <UploadButton onClick={setPhotoUpload} />
            <button
              className="saved-button"
              onClick={() => navigate("/savedPhotos")}
            >
              Saved Photos
            </button>
            <button
              className="saved-button"
              onClick={() => navigate("/albums")}
            >
              Albums
            </button>
            <div className="photo-count-container">
              <p>Loaded photos: {photoCount}</p>
            </div>
          </div>
          <div className="vertical-divider">
            <div></div>
          </div>
          <div>
            <div className="photos-container">
              {imageFilenames?.length > 0 ? (
                imageFilenames.map((filename, index) => {
                  if (pageNumber * 30 + 30 === index + 1) {
                    return (
                      <a
                        key={filename}
                        href={`${process.env.PUBLIC_URL}/photoPage/${imageFilenames[index]}`}
                        style={{
                          textDecoration: "inherit",
                          color: "inherit",
                          cursor: "auto",
                        }}
                        ref={isLastImageLoaded ? lastBookElementRef : null}
                      >
                        <GalleryItem
                          imageFilename={filename}
                          index={index}
                          setIsLastImageLoaded={setIsLastImageLoaded}
                          checked={checked}
                          checkBox={true}
                        />
                      </a>
                    );
                  } else {
                    return (
                      <a
                        key={filename}
                        href={`${process.env.PUBLIC_URL}/photoPage/${imageFilenames[index]}`}
                        style={{
                          textDecoration: "inherit",
                          color: "inherit",
                          cursor: "auto",
                        }}
                      >
                        <GalleryItem
                          imageFilename={filename}
                          index={index}
                          setChecked={setChecked}
                          checked={checked}
                          checkBox={true}
                        />
                      </a>
                    );
                  }
                })
              ) : (
                <div className="no-results-container">
                  <NoResults
                    primaryText="You didn't add any photos yet..."
                    secondaryText="Do it by clicking a button on the left"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Gallery;
