import React from "react";

const ConfirmDelete = ({
  functionAfterConfirm,
  setIsOpen,
  text,
  funcParams,
}) => {
  return (
    <div className="delete-confirmation">
      <div className="confirmation-card">
        <div className="confirmation-text">
          {text || "Are you sure you want to delete this person?"}
        </div>
        <div className="confirmation-buttons">
          <button className="confirm-buttons" onClick={() => setIsOpen(false)}>
            No
          </button>
          <button
            className="confirm-buttons confirm-delete"
            onClick={() => functionAfterConfirm(...funcParams)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
