import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import "./index.css";
import { useSelector } from "react-redux";

const CreatePerson = () => {
  const [searchParams] = useSearchParams();
  const faceId = searchParams.get("faceId") || null;
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const token = useSelector((state) => state.token);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT}/person`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            first_name: firstName,
            second_name: secondName,
            last_name: lastName,
            birth_date: birthDate,
            face_id: faceId,
          }),
        }
      );
      navigate(-1);
    } catch ({ name, message }) {
      console.log(message);
      setError(message);
    }
  };

  return (
    <>
      <Navbar />
      <div className="create-person-page">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <label htmlFor="secondName">Second Name</label>
        <input
          type="text"
          name="secondName"
          value={secondName}
          onChange={(e) => setSecondName(e.target.value)}
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <label htmlFor="birthDate">Birth Date</label>
        <input
          type="date"
          name="birthDate"
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
        />
        <button onClick={() => onSubmit()}>Create</button>
      </div>
    </>
  );
};

export default CreatePerson;
