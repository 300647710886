import Field from "components/field/Field";
import React, { useState } from "react";
import "./index.css";

const Notifications = () => {
  const [newsletterEnabled, setNewsletterEnabled] = useState(false);

  return <div className="notifications-container">awdwa</div>;
};

export default Notifications;
